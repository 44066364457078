
  
  .a-box {
    display: inline-block;
    width: 320px;
    text-align: center;
  }
  
  .img-container {
      height: 270px;
      width: 250px;
      overflow: hidden;
      border-radius: 0px 0px 20px 20px;
      display: inline-block;
  }
  
  .img-container img {
      height: 250px;
  }
  
  .inner-skew {
      display: inline-block;
      border-radius: 20px;
      overflow: hidden;
      padding: 0px;
      font-size: 0px;
      margin: 20px 0px 0px 0px;
      background: #c8c2c2;
      height: 245px;
      width: 215px;
  }
  
  .text-container {
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
    padding: 120px 20px 20px 20px;
    border-radius: 20px;
    background: #fff;
    margin: -120px 0px 0px 0px;
    line-height: 19px;
    font-size: 14px;
    height:350px;
  }
  
  .text-container-2 {
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
    padding: 120px 20px 20px 20px;
    border-radius: 20px;
    background: #fff;
    margin: -120px 0px 0px 0px;
    line-height: 19px;
    font-size: 14px;
    height:400px;
  }

  .text-container h3 {
    margin: 20px 0px 10px 0px;
    color: rgb(232, 0, 120);
    font-size: 22px;
    font-family: "RockoUltraFLF";
  }

  .text-container-2 h3 {
    margin: 20px 0px 10px 0px;
    color: rgb(232, 0, 120);
    font-size: 22px;
    font-family: "RockoUltraFLF";
  }

  .text-container p {
    font-family: 'Avenir';
    color:rgb(33, 67, 136);
  }

  .text-container-2 p{
    font-family: 'Avenir';
    color:rgb(33, 67, 136);
  }

  .text-container h6 {
    font-family: 'AvenirBold';
    color:rgb(33, 67, 136);
  }

  .text-container-2 h6{
    font-family: 'AvenirBold';
    color:rgb(33, 67, 136);
  }