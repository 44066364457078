

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	font-family: "Titillium Web", sans-serif;
}

:root {
	--mainColor: transparent;
	--mainColorLight: rgb(33, 67, 136);
	--secondaryColor: rgb(232, 0, 120);
	--textColor: #eee;
}

header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 80px;
	padding: 0 2rem;
	background-color: var(--mainColor);
	color: var(--textColor);
}


nav a {
	margin: 0 1.9rem;
	color: var(--textColor);
	text-decoration: none;
    font-family: 'RockoUltraflf';
    font-size: 0.75rem;
}

nav a:hover {
	color: var(--mainColorLight);
}

header .nav-btn {
	cursor: pointer;
	background: transparent;
	border: none;
	outline: none;
	color: var(--secondaryColor);
	visibility: hidden;
	opacity: 0;
	font-size: 2.2rem;
	position: absolute;
		top: 1rem;
		right: 2rem;
		z-index: 0;
}

header div,
nav {
	display: flex;
	align-items: center;
}

.logo_img{
    width:9rem;
}


.footer-service-list a {
	color: #fff;
  
	font-size: 11px;
  
	text-decoration: underline;
  }
  
  .footer-widget a {
	color: #ffffff;
  }
  
  .footer-widget h2 {
	color: #fff;
	font-size: 15px;
  }
  
  .copyright {
	color: #ffffff;
	font-size: 10px;
  }
  .social-links {
	background-image: url(../assets/icons/SocialBar-8.webp);
	background-size: 235px;
	background-position: 56% 0px;
	background-repeat: no-repeat;
  }
  
  .social-links a {
	display: inline-block;
	padding: 6px 11px;
  }
  
  .social-links a img {
	width: 29px;
  }
  
  footer.main-footer {
	background: #014384;
	padding: 1rem;
	position: relative;
	z-index: 9;
	bottom: 0;
	text-align: center;
  }

  .mobile_logo{
	display:flex;
  }

  .mobile_options{
	display:flex;
  }

  .pc_options{
	display:none;
  }

@media only screen and (min-width: 992px) {

	nav a:hover {
		color: var(--secondaryColor);
	}

	.mobile_options{
		display: none;
	}

	.pc_options{
		display:flex;
	  }

	.main_mobile_logo{
		display:none;
		text-align: center;
	}

    .language-toggle {
        display: flex;
        cursor: pointer;
    }

	.language-toggle img{
        width:2rem;
    }


    .mobile_logo{
        display: none;
    }
    
    header{
        padding-top:2rem;
    }

    .nav-container {
        width: 100%; /* Ensure the container takes up the full width */
        justify-content: space-between; /* Spread the items evenly */
    }

	footer.main-footer {
		background: #014384;
		padding: 2rem 10rem;
		position: relative;
		z-index: 9;
		bottom: 0;
		text-align: left;
	  }

  }

@media only screen and (max-width: 1024px) {


	.logo_img{
        display:none;
    }

	header .nav-btn {
		visibility: visible;
		opacity: 1;
	}

	header nav {
		position: fixed;
		top: -100vh;
		left: 0;
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 1rem;
		background-color: var(--secondaryColor);
		transition: 1s;
	}

	header .responsive_nav {
		transform: translateY(100vh);
	}

	nav .nav-close-btn {
		position: absolute;
		top: 3rem;
		right: 2rem;
		color:#fff;
	}

	nav a {
		font-size: 1.5rem;
	}
}