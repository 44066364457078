@font-face {
    font-family: "RockoUltraFLF";
    src: url("../assets/fonts/rockoultraflf.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "Avenir";
    src: url("../assets/fonts/26301410506.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: "AvenirBold";
    src: url("../assets/fonts/AvenirNextLTPro/AvenirNextLTPro-Bold.otf")
      format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
  
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    margin: 0;
    font-family: "RockoUltraFLF", sans-serif;
  }
  

.home_main{
    background-image: url("../assets/images/main_background.jpg");
    background-size: cover;
    background-attachment: fixed;
}

.blog_bg {
  position: relative;
  width: 100vw; /* Full viewport width */
  height: 100vh; /* Full viewport height */
  overflow: hidden; /* Hides content overflowing the viewport */
}

.blog_bg video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Cover the entire screen */
  z-index: -1; /* Ensure the video is behind the content */
}


.blog_bg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(33, 67, 136, 0.85); /* Blue with 40% opacity */
  z-index: 0; /* Ensure the overlay is above the video */
}

.content {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-x: auto;
}





.white_container{
  background-color:#fafafa; 
  padding:3rem 0 0 0;
}

.white_container_2{
  background-color:#fafafa; 
  padding:3rem 0 0 0 ;
}

.white_container_2 .download{
  padding:0 15rem;
}

.download img {
  width:6rem;
}

.blog_news{
  text-align: center;
  padding:3rem 10rem;
}

.white_container_3{
  background-image: url("../assets/images/home_background.png");
  background-size: cover;
  padding:3rem 0;
  text-align: center;
}

.white_container .hh1{
  color:rgb(232, 0, 120);
  font-family:'AvenirBold';
  text-align:Center;
  padding:0 8rem;
  font-size:28px;
}



.left_banner_box{
    text-align: right;
    transform: translate(6rem, 5rem);
}

.left_banner{
    width:45rem;
}

.left_banner_cf{
  width:35rem;
}

.right_banner_box{
    text-align: left;
    transform: translateX(-3rem);
}

.right_banner{
    width:32rem;
}

.apply-button{
  border:2px solid transparent; 
  background-color:#ffffff; 
  color:rgb(232, 0, 120); 
  border-radius:30px;
  font-family:'RockoUltraflf'; 
  text-decoration:none; 
  font-size:23px;
  padding:1rem 3rem;
}

.apply-button:hover{
  background-color:rgb(33, 67, 136); 
}

/* Add these styles to your existing styles/main.css file or create a new one */

.more-link {
  position: relative; /* Set position to relative for proper dropdown positioning */
}

.dropdown-menu {
  top: 100%; /* Position the dropdown below the "More" link */
  left: -35px;
}

.more-link:hover .dropdown-menu{
  display: block;
  flex-direction: column; /* Set the flex direction to column for a vertical list */
  background-color: transparent;
  border: none;
  text-align: center;
}

.dropdown-menu a{
  display: block;
  padding: 5px 0;
}

.personal_loan{
  text-align:right; 
  padding:2rem 15rem 2rem 2rem; 
  font-size:20px; 
  color:#ffffff;
}

.personal_loans_img{
  width:80%;
  padding:0 10rem 0 0;
}

.personal_loan_button{
  padding:0 10rem 0 0;
}

/* Styles for devices with width less than 992px */
@media (max-width: 991px) {
  /* Your CSS styles for smaller screens go here */
  .left_banner_box{
    text-align: right;
    transform: translate(0.5rem, 2rem);
}

.left_banner{
    width:14rem;
}

.right_banner_box{
  text-align: left;
  transform: translateX(-2rem);
}

.right_banner{
  width:12rem;
}

.white_container{
  background-color:#fafafa; 
  padding:1rem 0 0 0;
}

.white_container .hh1{
  color:rgb(232, 0, 120);
  font-family:'AvenirBold';
  text-align:Center;
  padding:0 2rem;
  font-size: medium;
}

.white_container_2 .download{
  padding:0 2rem;
}

.download img {
  width:4rem;
}

/* .white_container_3{
  display:none;
} */

.white_container_3{
  background-image: url("../assets/images/home_background_mobile.png");
  background-size:cover;
  text-align: center;
}


.personal_loan{
  text-align:center; 
  padding:2rem; 
  font-size:20px; 
  color:#ffffff;
}

.personal_loans_img{
  width:70%;
  padding:0rem;
}

.personal_loan_button{
  padding:0;
}

}





/* products page styling */

.products{
  text-align:center; 
  background-color:#fafafa; 
  padding:0 12rem;
}

.products_description{
  background-image: url("../assets/images/products_background.png");
  background-size:cover;
  background-repeat:no-repeat;
  padding:5rem 10rem;
}

.products_description h1{
  color:#ffffff;
  font-family: 'RockoUltraFLF';
  padding:2rem 12rem 0rem 2rem;
  text-align: right;
  font-size: 2.2rem;
}

.products_description span{
  color:rgb(232, 0, 120);
  font-family: 'RockoUltraFLF';
  font-size: 2.2rem;
}


@media (max-width: 991px) {
  .products_description{
    background-image: url("../assets/images/products_background_mobile.png");
    background-size:cover;
    background-repeat:no-repeat;
  }
  
  .products_description h1{
    color:#ffffff;
    font-family: 'RockoUltraFLF';
    padding:2rem;
    font-size: 2.2rem;
  }
}

/* contact us page styling */

.contact_heading h1 {
  font-family: "RockoUltraflf";
  font-size: 5rem;
  padding-left: 6rem;
  color: #ffffff;
}

.contact-button{
  border:2px solid transparent; 
  background-color: #f20076; 
  color:#ffffff; 
  border-radius:30px;
  font-family:'RockoUltraflf'; 
  text-decoration:none; 
  font-size:15px;
}

.meet-our-team{
  border:2px solid transparent; 
  background-color: #f20076; 
  color:#ffffff; 
  border-radius:30px;
  font-family:'RockoUltraflf'; 
  text-decoration:none; 
  font-size:22px;
}

.meet-our-team-heading{ 
  background-image: linear-gradient(60deg, rgb(33, 67, 136), rgb(232, 0, 120));
  background-clip: text;
  color: transparent;
  font-family:'RockoUltraflf'; 
}


@media (max-width: 991px) {
  .contact_heading h1 {
    font-family: "RockoUltraflf";
    font-size: 3rem;
    padding-left: 1rem;
    color: #ffffff;
  }
}


/* financial-literacy page styling */

.fl-banner-img{
  width:46rem;
  position:absolute;
  left:-5rem;
}

.fl_heading h1{
  font-size: 4.7rem;
  justify-content: end;
  font-family: 'RockoUltraFLF';
  color: #ffffff;
  text-align: right;
  margin-top: 5%;
  padding-bottom: 2rem;
  margin-right: 10rem;
}

.fl_description{
  color:rgb(33, 67, 136);
  font-family: AvenirBold;
  text-align: right;
  margin-right: 12rem;
  line-height: 2.5rem;
}

.free_workshop{
  color:rgb(232, 0, 120);
  font-family: RockoUltraFLF;
  text-align: right;
  padding:8% 0 0 0;
  margin-right: 12rem;
}

.signup_button{
  color:rgb(232, 0, 120);
  font-family: RockoUltraFLF;
  text-align: right;
  border:2px solid rgb(33, 67, 136);
  padding:0.2rem 3rem;
  border-radius: 50px;
  background-color: rgb(33, 67, 136);
  text-decoration: none;
  font-size: 2.3rem;
  margin-right: 12rem;
}

.fl_dinner{
  font-family: 'RockoUltraFLF';
  color:rgb(232, 0, 120);
  padding: 2rem 0;
}

.fl_dinner span{
  font-family: 'RockoUltraFLF';
  color:#ffffff;
}

.fl_dinero{
  border:2px solid rgb(232, 0, 120);
  background-color: rgb(232, 0, 120);
  border-radius: 20px;
  font-style: italic;
  color:#ffffff;
  padding:0.7rem 2rem 0 2rem;
  height:12rem;
  margin: 0 1rem;
}

.fl_testimonials{
  border:2px solid rgb(232, 0, 120);
  background-color: rgb(232, 0, 120);
  border-radius: 20px;
  font-style: italic;
  color:#ffffff;
  padding:0.7rem 2rem 0 2rem;
  height:21rem;
}

.fl_testimonials span{
  font-weight: bold;
}

.fl_community{
  background-color: rgb(232, 0, 120);
  color:#ffffff;
  font-family: 'RockoUltraFLF';
  text-align: center;
}

.fl_community h2{
  font-family: 'RockoUltraFLF';
}

.fl_community h3{
  font-family: 'AvenirBold';
  font-style: italic;
  color:rgb(33, 67, 136);
}

.fl_community h3 span{
  font-family: 'AvenirBold';
  font-style: italic;
  color:#ffffff;
}

.fl_community h2 span{
  color:rgb(33, 67, 136);
  font-family: 'RockoUltraFLF';
}

.fl_safe_space{
  text-align:center;
  padding:0 10rem;
}



@media (max-width: 991px) {
  .fl-banner-img{
    width:20rem;
    position:absolute;
    margin-left:0.7rem;
  }

  .fl_heading h1{
    font-size: 2.0rem;
    font-family: 'RockoUltraFLF';
    color: #ffffff;
    margin-top: 3rem;
    padding-bottom: 1rem;
  }
  
  .fl_description{
    color:rgb(33, 67, 136);
    font-family: AvenirBold;
    text-align: center;
    line-height: 2.5rem;
    margin-right: 1rem;
  }
  
  .free_workshop{
    color:rgb(232, 0, 120);
    font-family: RockoUltraFLF;
    text-align: center;
    margin-right: 1rem;
  }
  
  .signup_button{
    color:rgb(232, 0, 120);
    font-family: RockoUltraFLF;
    text-align: center;
    border:2px solid rgb(33, 67, 136);
    padding:0.2rem 3rem;
    border-radius: 50px;
    background-color: rgb(33, 67, 136);
    text-decoration: none;
    font-size: 1.5rem;
    margin-right: 1rem;
  }
  
  .fl_dinner{
    font-family: 'RockoUltraFLF';
    color:rgb(232, 0, 120);
    padding: 2rem 0;
  }
  
  .fl_dinner span{
    font-family: 'RockoUltraFLF';
    color:#ffffff;
  }
  
  .fl_dinero{
    border:2px solid rgb(232, 0, 120);
    background-color: rgb(232, 0, 120);
    border-radius: 20px;
    font-style: italic;
    color:#ffffff;
    padding:0.7rem 2rem 0 2rem;
    height:12rem;
    margin: 0 1rem;
  }
  
  .fl_testimonials{
    border:2px solid rgb(232, 0, 120);
    background-color: rgb(232, 0, 120);
    border-radius: 20px;
    font-style: italic;
    color:#ffffff;
    padding:0.7rem 2rem 0 2rem;
    height:21rem;
  }
  
  .fl_testimonials span{
    font-weight: bold;
  }
  
  .fl_community{
    background-color: rgb(232, 0, 120);
    color:#ffffff;
    font-family: 'RockoUltraFLF';
    text-align: center;
  }
  
  .fl_community h2{
    font-family: 'RockoUltraFLF';
  }
  
  .fl_community h3{
    font-family: 'AvenirBold';
    font-style: italic;
    color:rgb(33, 67, 136);
  }
  
  .fl_community h3 span{
    font-family: 'AvenirBold';
    font-style: italic;
    color:#ffffff;
  }
  
  .fl_community h2 span{
    color:rgb(33, 67, 136);
    font-family: 'RockoUltraFLF';
  }

  .fl_safe_space{
    text-align:center;
    padding:0 0rem;
  }
}

/* Disclosures page styles */
.container-wrapper{
  border: 2px solid #ffffff;
  padding-bottom: 5rem;
  background-color: #ffffff;
  border-radius: 1.5rem;
  margin:3rem 10rem;
}


@media (max-width: 991px) {
  .container-wrapper{
    border: 2px solid #ffffff;
    padding-bottom: 5rem;
    background-color: #ffffff;
    border-radius: 1.5rem;
    margin:3rem 0rem;
  }
}



/* CF styles */

.CF_left_banner{
  width:28rem;
}

.CF_right_banner{
  text-align: left;
  transform: translateX(-3rem);
}

.CF_right_banner_box{
  text-align: left;
  transform: translateX(1rem);
}

.left_CF_box{
  text-align: right;
  transform: translate(15rem, 7rem);
}

.left_CF_box_2{
  text-align: right;
  transform: translate(15rem, -2rem);
}

.left_CF_box .left_text_box{
  border:2px solid rgb(232,0 120); 
  background-color:rgb(232, 0, 120); 
  color:#ffffff;
  padding:3rem 15rem 2.5rem 3rem;
  text-align:left;
  font-family: 'Avenir';
  font-size: 20px;
  line-height: 2rem;
  border-radius: 2rem;
}

.left_CF_box_2 .left_text_box_2{
  border:2px solid #ffffff; 
  color:#ffffff;
  padding:1.5rem 15rem 0.5rem 2rem;
  text-align:left;
  font-family: 'Avenir';
  font-size: 16px;
  line-height: 2rem;
  border-radius: 2rem;
}

.left_CF_box_2 .left_text_box_2 ul li{
font-family:'RockoUltraFLF';

}

.CF_culture{
  font-family: 'RockoUltraFLF';
  color:rgb(232, 0, 120);
  padding: 2rem 10rem;
  font-size: 45px;
}

.CF_culture span{
  font-family: 'RockoUltraFLF';
  color:#ffffff;
}

.eligible_text{
  text-align: center;
  border: 2px solid rgb(232, 0, 120);
  background-color: rgb(232, 0, 120);
  padding: 1rem;
  border-radius: 3rem;
  margin:4rem 10rem;
}

.eligible_text li{
  color: #ffffff;
  font-family: 'AvenirBold';
  text-align: left;
}

.eligible_text img{
  transform: translateY(-3rem);
  width:45vw;
}

.CF_apply_button{
color:rgb(232, 0, 120);
border-radius: 3rem;
background-color: #ffffff;
text-decoration: none;
font-family: 'RockoUltraFLF';
padding: 1.2rem 4rem;
font-size: 22px;
}

.faqs{
  text-align: left;
  margin: 1rem 10rem;
}

.faqs h1{
  color: rgb(232, 0, 120);
  font-family: 'RockoUltraFLF';
  padding:3rem 0;
}

.faqs li{
  color: #ffffff;
  font-family: 'Avenir';
}


@media (max-width: 991px) {
  .left_banner_cf {
    width:20rem;
  }

  .CF_left_banner{
    width:18rem;
  }

  .CF_right_banner{
    text-align: center;
    transform: translateX(0rem);
  }
  
  .CF_right_banner_box{
    text-align: center;
    transform: translateY(-3.5rem);
  }
  
  .left_CF_box{
    text-align: right;
    transform: translate(0rem, 0rem);
  }
  
  .left_CF_box_2{
    text-align: center;
    transform: translate(0rem, 0rem);
    padding:0 2rem;
  }
  
  .left_CF_box .left_text_box{
    border:2px solid rgb(232,0 120); 
    background-color:rgb(232, 0, 120); 
    color:#ffffff;
    padding:1.5rem 1.5rem 4rem 1.5rem;
    text-align:left;
    font-family: 'Avenir';
    font-size: 20px;
    line-height: 2rem;
    border-radius: 2rem;
  }
  
  .left_CF_box_2 .left_text_box_2{
    border:2px solid #ffffff; 
    color:#ffffff;
    padding:1.5rem;
    text-align:left;
    font-family: 'Avenir';
    font-size: 16px;
    line-height: 2rem;
    border-radius: 2rem;
  }
  
  .left_CF_box_2 .left_text_box_2 ul li{
  font-family:'RockoUltraFLF';
  
  }
  
  .CF_culture{
    font-family: 'RockoUltraFLF';
    color:rgb(232, 0, 120);
    padding: 2rem;
    font-size: 40px;
  }
  
  .CF_culture span{
    font-family: 'RockoUltraFLF';
    color:#ffffff;
  }
  
  .eligible_text{
    text-align: center;
    border: 2px solid rgb(232, 0, 120);
    background-color: rgb(232, 0, 120);
    padding: 1rem;
    border-radius: 3rem;
    margin:3rem 1rem;
  }
  
  .eligible_text li{
    color: #ffffff;
    font-family: 'AvenirBold';
    text-align: left;
  }
  
  .eligible_text img{
    transform: translate(-1rem, -2rem);
    width:85vw;
  }
  
  .CF_apply_button{
  color:rgb(232, 0, 120);
  border-radius: 3rem;
  background-color: #ffffff;
  text-decoration: none;
  font-family: 'RockoUltraFLF';
  padding: 1.2rem 4rem;
  font-size: 22px;
  }
  
  .faqs{
    text-align: left;
    margin: 1rem;
  }
  
  .faqs h1{
    color: rgb(232, 0, 120);
    font-family: 'RockoUltraFLF';
    padding:3rem 0;
  }
  
  .faqs li{
    color: #ffffff;
    font-family: 'Avenir';
  }
}




/* about page styling */

.left_about_box{
  text-align: left;
  transform: translate(-8rem, 10rem);
  z-index: 2;
}

.left_about{
  width:62rem;
  z-index: 2;
  position: relative;
}

.right_about_box{
  text-align: left;
  transform: translate(-10rem, 5rem);
  z-index: 0;
}

.right_about{
  width:72rem;
  z-index: -1;
  position: relative;
}

.left_boy_box{
  text-align: right;
  transform: translate(0rem, -12rem);
  z-index: 2;
}

.left_boy{
  width:36rem;
  z-index: 2;
  position: relative;
}

.right_boy_box{
  text-align: right;
  transform: translate(-40rem, 10rem);
}

.right_boy{
  width:72rem;
  z-index: -1;
  position: relative;
  color:#ffffff;
  font-family: 'Avenir';
  font-size: 35px;
}

.about_text{
  font-size: 20px;
  font-family: 'Avenir';
  color:rgb(33, 67, 136);
  padding:5rem 10rem;
}

.about_text span{
  font-size: 20px;
  font-family: 'RockoUltraFLF';
  color:rgb(232, 0, 120);
}
.left_behind_box{
  text-align: left;
  transform: translate(12rem, -3rem);
  z-index: 2;
}
.left_behind_box h1{
  color:#ffffff;
  font-family: 'RockoUltraFLF';
  font-size: 100px;
  line-height:5rem;
}
.left_behind_box span{
  color:rgb(232, 0, 120);
  font-family: 'RockoUltraFLF';
}

.left_behind_box p{
  font-family: 'Avenir';
  font-size: 19px;
}

.right_behind_box{
  text-align: left;
  transform: translate(0rem, -15rem);
  z-index: 0;
}

.right_behind_box img{
  width:30rem;
}
.right_behind_box p{
font-family: 'Avenir';
font-size: 19px;
}

.left_help_box{
  text-align: left;
  transform: translate(0rem, 3rem);
  z-index: 2;
}
.left_help_box p{
  color:#ffffff;
  font-family: 'Avenir';
}
.left_help_box p span{
  color:rgb(232, 0, 120);
  font-family: 'RockoUltraFLF';
}

.right_help_box{
  text-align: right;
  transform: translate(6rem, -3rem);
  z-index: 0;
  border:2px solid rgb(232, 0, 120);
  background-color: rgb(232, 0, 120);
  padding: 1rem 2rem;
  border-radius: 25px;
}

.right_help_box p{
color:#ffffff;
}
.right_help_box p span{
  color:rgb(33, 67, 136);
  font-family: 'RockoUltraFLF';
}

.white_about_container{
  background-color:#fafafa; 
  padding:3rem 0 0 0;
  text-align:center; 
  padding:0 15rem; 
  transform:translateY(-12rem); 
  position:relative; 
  z-index:5;
}

.unique{
  width:45rem;
}

@media (max-width: 991px) {
  .left_about_box{
    text-align: left;
    transform: translate(-10rem, 3rem);
    z-index: 2;
  }
  
  .left_about{
    width:32rem;
    z-index: 2;
    position: relative;
  }
  
  .right_about_box{
    text-align: left;
    transform: translate(-10rem, 7rem);
    z-index: 0;
  }
  
  .right_about{
    width:26rem;
    z-index: -1;
    position: relative;
  }
  
  .left_boy_box{
    text-align: right;
    transform: translate(0rem, 10rem);
    z-index: 2;
  }
  
  .left_boy{
    width:25rem;
    z-index: 2;
    position: relative;
    transform: scaleX(-1);
  }
  
  .right_boy_box{
    text-align: left;
    transform: translate(0rem, 10rem);
  }
  
  .right_boy{
    width:42rem;
    z-index: -1;
    position: relative;
    color:#ffffff;
    font-family: 'Avenir';
    font-size: 25px;
  }
  
  .about_text{
    font-size: 20px;
    font-family: 'Avenir';
    color:rgb(33, 67, 136);
    padding:5rem 10rem;
  }
  
  .about_text span{
    font-size: 20px;
    font-family: 'RockoUltraFLF';
    color:rgb(232, 0, 120);
  }
  .left_behind_box{
    text-align: left;
    transform: translate(2rem, 40rem);
    z-index: 2;
  }
  .left_behind_box h1{
    color:#ffffff;
    font-family: 'RockoUltraFLF';
    font-size: 50px;
    line-height:3rem;
  }
  .left_behind_box span{
    color:rgb(232, 0, 120);
    font-family: 'RockoUltraFLF';
  }
  
  .left_behind_box p{
    font-family: 'Avenir';
    font-size: 19px;
  }
  
  .right_behind_box{
    text-align: left;
    transform: translate(0rem, -10rem);
    z-index: 0;
  }

.right_behind_box img{
  width:20rem;
}

  
  .right_behind_box p{
  font-family: 'Avenir';
  font-size: 19px;
  }
  
  .left_help_box{
    text-align: left;
    transform: translate(-2rem, 0rem);
    z-index: 2;
  }
  .left_help_box p{
    color:#ffffff;
    font-family: 'Avenir';
  }
  .left_help_box p span{
    color:rgb(232, 0, 120);
    font-family: 'RockoUltraFLF';
  }
  
  .right_help_box{
    text-align: right;
    transform: translate(2rem, -8rem);
    z-index: 0;
    border:2px solid rgb(232, 0, 120);
    background-color: rgb(232, 0, 120);
    padding: 1rem 2rem;
    border-radius: 25px;
  }
  
  .right_help_box p{
  color:#ffffff;
  }
  .right_help_box p span{
    color:rgb(33, 67, 136);
    font-family: 'RockoUltraFLF';
  }

  .white_about_container{
    background-color:#fafafa; 
    padding:3rem 0 0 0;
    text-align:center; 
    padding:0 1rem; 
    transform:translateY(10rem); 
    position:relative; 
    z-index:5;
  }

  .unique{
    width:20rem;
  }
}