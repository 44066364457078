@charset "UTF-8";
@font-face {
    font-family: "RockoUltraFLF";
    src: url("../assets/fonts/rockoultraflf.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }


a {
  text-decoration: none;
}

h1 {
  font-family: 'RockoUltraFLF';
  font-weight: 300;
}

.main_card{
  padding:1rem 1rem;
}

.card {
  float: left;
  width: 100%;
  background-color: transparent;
}
.card .menu-content {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.card .menu-content::before, .card .menu-content::after {
  content: "";
  display: table;
}
.card .menu-content::after {
  clear: both;
}
.card .menu-content li {
  display: inline-block;
}
.card .menu-content a {
  color: #fff;
}
.card .menu-content span {
  position: absolute;
  top: 0;
  font-size: 10px;
  font-weight: 700;
  font-family: 'RockoUltraFLF';
  transform: translate(-50%, 0);
}
.card .wrapper {
  min-height: 440px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.2);
}
.card .wrapper:hover .data {
  transform: translateY(0);
}

.card .wrapper:hover .data .title_link{
  color:rgb(232, 0, 120) !important;
}

.card .data {
  position: absolute;
  bottom: 0;
  width: 100%;
  transform: translateY(calc(70px + 1em));
  transition: transform 0.3s;
}
.card .data .content {
  padding: 1em;
  position: relative;
  z-index: 1;
}
.card .author {
  font-size: 12px;
}
.card .title {
  margin-top: 10px;
}

.title_link{
  font-family: 'RockoUltraFLF';
  font-size: 25px;
}


.card .text {
  height: 70px;
  margin: 0;
}
.card input[type=checkbox] {
  display: none;
}
.card input[type=checkbox]:checked + .menu-content {
  transform: translateY(-60px);
}


.share-icon {
  width: 20px; /* Adjust the size as needed */
  height: 20px; /* Adjust the size as needed */
  cursor: pointer;
  margin-left: auto; /* Move the icon to the extreme right */
  position: relative;
}

.header{
  display: flex; /* Use flexbox for the header */
  align-items: center; /* Align children vertically */
}

  .example-2 .header {
    color: #fff;
    padding: 1em;
  }
  .example-2 .header::before, .example-2 .header::after {
    content: "";
    display: table;
  }
  .example-2 .header::after {
    clear: both;
  }
  .example-2 .header .date {
    float: left;
    font-size: 12px;
    position: relative;
  }
  .example-2 .menu-content {
    float: right;
  }
  .example-2 .menu-content li {
    margin: 0 5px;
    position: relative;
  }
  .example-2 .menu-content span {
    transition: all 0.3s;
    opacity: 0;
  }
  .example-2 .data {
    color: #fff;
    transform: translateY(calc(10px + 0em));
  }
  .example-2 .title a {
    color: #fff;
  }
  .example-2 h2 {
    color: #fff;
    font-family: 'RockoUltraFLF';
}

.resp_blog{
  padding:5rem 17rem;
}

.resp_blog h1{
  color:rgb(232, 0, 120);
}

.resp_blog p{
  color:#ffffff;
  font-size: 18px;
  font-family: 'Avenir';
}

.resp_blog b{
  color:#ffffff;
  font-size: 18px;
  font-family: 'Avenir';
}

.resp_blog i{
  color:#ffffff;
  font-size: 25px;
}

.seeAllBlog{
  width:70%;
}

.seeAllBlog img{
  width:90%;
}

.seeAllBlog h1{
  color: rgb(232, 0, 120);
  font-size: 20px;
  padding: 3rem 1rem;
}




@media (min-width:481px){
  .main_card{
    padding:1rem 10rem;
  }
  
}

@media (max-width: 991px) {
  .resp_blog{
    padding:1rem;
  }

  .seeAllBlog{
    width:90%;
  }

  .seeAllBlog h1{
    color: rgb(232, 0, 120);
    font-size: 16px;
    padding: 1rem 0.1rem;
  }
  
}