/* Apply the animation to an element */
.slide-in-left {
    animation: slideInLeft 1.5s ease-out;
  }
    
  .slide-in-right {
    animation: slideInRight 1.5s ease-out;
  }
  
  .grow-effect {
      animation: grow 2s ease-out;
  }
  
  .circular-grow {
      animation: circularGrow 1.5s linear forwards;
  }
  
  @keyframes slideInLeft {
    0% {
      opacity: 0;
      transform: translateX(-30%);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  @keyframes slideInRight {
    0% {
      opacity: 0;
      transform: translateX(30%);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  @keyframes grow {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  @keyframes circularGrow {
    0% {
      transform: scale(0) rotate(0deg);
    }
    50% {
      transform: scale(0.5) rotate(180deg);
    }
    100% {
      transform: scale(1) rotate(360deg);
    }
  }
  